import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import JBImage from 'components/essentials/JBImage';
import normalizeInternalLink from 'commons/normalizeInternalLink';
import useScreen from 'hooks/useScreen';

const baseUrl =
  'https://joybird2.imgix.net/static-v1/consumer-home-page/categories-navigation';
const categories = [
  {
    title: 'Sofas & Sectionals',
    image: `${baseUrl}/Sofa-Category.png`,
    imageHover: `${baseUrl}/Sofa-Category-Hover.jpg?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&ixlib=react-8.6.1&h=280&cs=srgb`,
    url: '/sofas-and-sectionals',
  },
  {
    title: 'Chairs',
    image: `${baseUrl}/Chair-Category.png`,
    imageHover: `${baseUrl}/Chair-Category-Hover.jpg?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&ixlib=react-8.6.1&h=280&cs=srgb`,
    url: '/chairs',
  },
  {
    title: 'Bedroom',
    image: `${baseUrl}/Bed-Category.png`,
    imageHover: `${baseUrl}/Bed-Category-Hover.jpg?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&ixlib=react-8.6.1&h=280&cs=srgb`,
    url: '/bedroom',
  },
  {
    title: 'Tables',
    image: `${baseUrl}/Table-Category.png`,
    imageHover: `${baseUrl}/Table-Category-Hover.jpg?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&ixlib=react-8.6.1&h=280&cs=srgb`,
    url: '/all-tables',
  },
  {
    title: 'Storage',
    image: `${baseUrl}/Storage-Category.png`,
    imageHover: `${baseUrl}/Storage-Category-Hover.jpg?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&ixlib=react-8.6.1&h=280&cs=srgb`,
    url: '/all-storage',
  },
  {
    title: 'Decor',
    image: `${baseUrl}/Decor-Category.png`,
    imageHover: `${baseUrl}/Decor-Category-Hover.jpg?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&ixlib=react-8.6.1&h=280&cs=srgb`,
    url: '/decoration-accessories',
  },
];

const RenderBlock = props => {
  const { isMobileOrTablet, windowWidth } = useScreen();

  return (
    <Link
      to={normalizeInternalLink(props.url)}
      className="focus-visible:outline-offset-[-1px]"
    >
      <div
        className={classNames(
          'flex justify-between items-center flex-col h-full max-[767px]:flex-col-reverse group-hover/CategoriesNavigation-item:hidden'
        )}
      >
        <div className="text-lg text-center font-bold md:mb-2 md:mt-2 min-[1440px]:mb-6 min-[1440px]:mt-4 max-[767px]:text-base max-[767px]:font-bold h-1/2">
          {props.title}
        </div>
        <div className="flex justify-center md:mb-10 md:mt-4 h-1/2">
          <JBImage
            src={props.image}
            height={isMobileOrTablet || windowWidth < 1440 ? 80 : 100}
            width={isMobileOrTablet || windowWidth < 1440 ? 160 : 200}
            loader={false}
            alt=""
            fit="clip"
          />
        </div>
      </div>

      <div
        className="hidden w-full h-full justify-end flex-col p-4 bg-cover bg-no-repeat [transition:all_.3s_ease-in] group-hover/CategoriesNavigation-item:!flex"
        style={{ backgroundImage: `url(${props.imageHover})` }}
      >
        <div>
          <div className="bg-white text-center font-bold md:p-4 rounded-sm text-base text-gray">
            Shop {props.title}
          </div>
        </div>
      </div>
    </Link>
  );
};

RenderBlock.propTypes = {
  image: PropTypes.string,
  imageHover: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

const CategoriesNavigation = props => (
  <div className="text-[.9rem] mt-[-60px] z-20 relative overflow-auto pb-3 pl-[3.333vw] pr-[3.333vw] min-[1440px]:text-base print:hidden">
    <div className="mx-auto my-0 max-w-[1400px]">
      <ul
        className={classNames(
          'flex list-none m-0 justify-around max-[767px]:grid max-[767px]:gap-[.5rem] max-[767px]:grid-cols-[repeat(auto-fit,minmax(30%,1fr))]',
          '[&_li]:flex-[1_1] [&_li]:h-[140px] [&_li]:bg-white [&_li]:mr-[3.333vw] [&_li]:rounded-sm [&_li]:overflow-hidden [&_li]:[transition:all_.3s_ease-in] [&_li]:md:h-[180px] [&_li]:md:mr-[15px] [&_li]:min-[1440px]:h-60 [&_li]:min-[1440px]:mr-5',
          '[&_li:hover]:shadow-[0_0_4px_0_rgba(0,0,0,.06)_,0_2px_4px_0_rgba(0,0,0,.12)]',
          '[&_li:first-child]:ml-0',
          '[&_li:last-child]:md:mr-0',
          '[&_a]:text-gray [&_a]:no-underline [&_a]:max-[767px]:items-center [&_a]:max-[767px]:flex [&_a]:max-[767px]:justify-center [&_a]:max-[767px]:mr-0 [&_a]:max-[767px]:h-full',
          '[&_img]:[transition:all_.3s_ease-in-out] [&_img]:max-w-[80%] [&_img]:max-h-20 [&_img]:object-contain [&_img]:min-[1440px]:max-w-[160px] [&_img]:min-[1440px]:max-h-[100px]'
        )}
      >
        {props?.config?.enabled?.override !== 'yes' &&
          categories.map(item => (
            <li key={item.url} className="group/CategoriesNavigation-item">
              <RenderBlock {...item} />
            </li>
          ))}
        {props?.config?.enabled?.override === 'yes' &&
          [
            {
              title: props?.config?.category1?.thumbnailLabel || '',
              image: props?.config?.category1?.thumbnailImage || '',
              imageHover: props?.config?.category1?.hoverImage || '',
              url: props?.config?.category1?.url || '',
            },
            {
              title: props?.config?.category2?.thumbnailLabel || '',
              image: props?.config?.category2?.thumbnailImage || '',
              imageHover: props?.config?.category2?.hoverImage || '',
              url: props?.config?.category2?.url || '',
            },
            {
              title: props?.config?.category3?.thumbnailLabel || '',
              image: props?.config?.category3?.thumbnailImage || '',
              imageHover: props?.config?.category3?.hoverImage || '',
              url: props?.config?.category3?.url || '',
            },
            {
              title: props?.config?.category4?.thumbnailLabel || '',
              image: props?.config?.category4?.thumbnailImage || '',
              imageHover: props?.config?.category4?.hoverImage || '',
              url: props?.config?.category4?.url || '',
            },
            {
              title: props?.config?.category5?.thumbnailLabel || '',
              image: props?.config?.category5?.thumbnailImage || '',
              imageHover: props?.config?.category5?.hoverImage || '',
              url: props?.config?.category5?.url || '',
            },
            {
              title: props?.config?.category6?.thumbnailLabel || '',
              image: props?.config?.category6?.thumbnailImage || '',
              imageHover: props?.config?.category6?.hoverImage || '',
              url: props?.config?.category6?.url || '',
            },
          ]
            .filter(entry => entry.url !== '')
            .map(item => (
              <li key={item.url} className="group/CategoriesNavigation-item">
                <RenderBlock {...item} />
              </li>
            ))}
      </ul>
    </div>
  </div>
);

CategoriesNavigation.propTypes = {
  config: PropTypes.shape({
    enabled: PropTypes.shape({
      override: PropTypes.string,
    }),
    category1: PropTypes.shape({
      hoverImage: PropTypes.string,
      thumbnailImage: PropTypes.string,
      thumbnailLabel: PropTypes.string,
      url: PropTypes.string,
    }),
    category2: PropTypes.shape({
      hoverImage: PropTypes.string,
      thumbnailImage: PropTypes.string,
      thumbnailLabel: PropTypes.string,
      url: PropTypes.string,
    }),
    category3: PropTypes.shape({
      hoverImage: PropTypes.string,
      thumbnailImage: PropTypes.string,
      thumbnailLabel: PropTypes.string,
      url: PropTypes.string,
    }),
    category4: PropTypes.shape({
      hoverImage: PropTypes.string,
      thumbnailImage: PropTypes.string,
      thumbnailLabel: PropTypes.string,
      url: PropTypes.string,
    }),
    category5: PropTypes.shape({
      hoverImage: PropTypes.string,
      thumbnailImage: PropTypes.string,
      thumbnailLabel: PropTypes.string,
      url: PropTypes.string,
    }),
    category6: PropTypes.shape({
      hoverImage: PropTypes.string,
      thumbnailImage: PropTypes.string,
      thumbnailLabel: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

export default CategoriesNavigation;
